<template>
  <div>
    <Loader v-if="isLoading" />
    <div v-else>
      <h3 style="margin-bottom: 25px">{{ $l.get("report-create.header") }}</h3>
      <ReportForm
        :report="report"
        :plans="data.plans"
        :onSubmit="onSubmit"
        v-if="data"
      />
    </div>
  </div>
</template>

<script>
import InitReportCreateInteractor from "@/business/report-create/init-report-create";
import SubmitFormInteractor from "@/business/report-create/submit-form";
import ReportCreateScreenController from "@/adapters/controllers/screen-report-create";
import ReportForm from "@/application/components/reports/ReportForm";
import Loader from "@/application/components/Loader";

export default {
  screen_name: "report-create",
  components: { ReportForm, Loader },
  data() {
    return {
      controller: null,
      interactors: {
        initReportCreate: null,
        submitForm: null
      },
      data: null,
      isLoading: false,
      report: {
        titleEnUs: "",
        titlePtBr: "",
        titleEsEs: "",
        subtitleEnUs: "",
        subtitlePtBr: "",
        subtitleEsEs: "",
        permissionType: 1,
        dataSourceType: 1,
        dataSourceVisibility: 1,
        variants: []
      }
    };
  },
  beforeMount() {
    this.controller = this.$injector.get(ReportCreateScreenController);

    //{ INTERACTORS
    this.interactors.submitForm = this.$injector.get(SubmitFormInteractor);
    this.interactors.initReportCreate = this.$injector.get(
      InitReportCreateInteractor
    );
    //} INTERACTORS

    this.controller.setData = this.setData;
    this.controller.getData = this.getData;
    this.controller.setAsLoading = this.setAsLoading;
    this.controller.setAsLoaded = this.setAsLoaded;
  },
  mounted() {
    this.interactors.initReportCreate.handle();
  },
  methods: {
    setData(data) {
      this.data = data;
    },
    getData() {
      return this.data;
    },
    setAsLoading() {
      this.isLoading = true;
    },
    setAsLoaded() {
      this.isLoading = false;
    },
    onSubmit() {
      this.report.plans = [];
      for (let plan of this.data.plans) {
        if (plan.isSelected) {
          this.report.plans.push({
            plan: plan.id
          });
        }
      }
      for (let variant of this.report.variants) {
        if (variant.figure != null) {
          variant.figure = variant.figure.id;
        }
      }
      this.interactors.submitForm.handle(this.report);
    }
  }
};
</script>

<style lang="scss"></style>
